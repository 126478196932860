<!-- 加入我们 -->
<template>
  <div class="wrapper pageBox">
    <div class="banner">
      <img src="@/assets/img/banner3.png">
      <div class="bannerText">
        <div class="title">加入中嘉数科数字化行业伙伴</div>
        <div class="btn"><div class="company">中嘉数科</div><div class="colLine" />数字赋能未来生活</div>
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="connect">
      <div class="conBox">
        <!-- 招募对象区域 -->
        <div class="topText">
          <div class="title">申请表</div>
        </div>
        <!-- 表单区域 -->
        <div class="form-box">
          <el-form ref="joinForm" :model="joinForm" :rules="joinRules" label-width="130px" label-position="right">
            <el-form-item label="姓名：" prop="name">
              <el-input v-model="joinForm.name" placeholder="我们该怎么称呼您" style="width: 580px" />
            </el-form-item>
            <el-form-item label="公司：" prop="company">
              <el-input v-model="joinForm.company" placeholder="以营业执照为准" style="width: 580px" />
            </el-form-item>
            <el-form-item label="职位：" prop="position">
              <el-input v-model="joinForm.position" placeholder="您的职位是什么" style="width: 580px" />
            </el-form-item>
            <el-form-item label="手机：" prop="phone">
              <el-input v-model="joinForm.phone" placeholder="留下联系方式，我们会尽快给您电话" style="width: 580px" />
            </el-form-item>
            <el-form-item label="邮箱：" prop="email">
              <el-input v-model="joinForm.email" placeholder="留下邮箱，方便进一步联系" style="width: 580px" />
            </el-form-item>
            <el-form-item ref="city" label="所在城市：" prop="city">
              <el-input v-model="joinForm.city" placeholder="我们该怎么称呼您" style="display: none" />
              <div class="area-box">
                <el-select v-model="provinceValue" placeholder="请选择" style="width:193px" @change="selectProvimce">
                  <el-option v-for="(item,index) of provincearr" :key="index" :label="item.name" :value="item.id" />
                </el-select>
                <el-select v-model="cityValue" placeholder="请选择" style="width:193px" @change="selectcity">
                  <el-option v-for="(item,index) of cityarr" :key="index" :label="item.name" :value="item.id" />
                </el-select>
                <el-select v-model="RegionValue" placeholder="请选择" style="width:193px" @change="selectRegion">
                  <el-option v-for="(item,index) of regionarr" :key="index" :label="item.name" :value="item.id" />
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="意向经营区域：" prop="operatingArea">
              <el-input v-model="joinForm.operatingArea" placeholder="请输入" style="width: 580px" />
            </el-form-item>
            <el-form-item label="合作伙伴类型：" prop="cooperationType">
              <!-- <el-input v-model="joinForm.cooperationType" placeholder="我们该怎么称呼您" style="width: 580px" /> -->
              <div class="radio-box">
                <el-radio v-model="joinForm.cooperationType" label="研发生产ISV生态伙伴" />
                <el-radio v-model="joinForm.cooperationType" label="智能终端生产生态伙伴" />
                <el-radio v-model="joinForm.cooperationType" label="系统集成商生态伙伴" />
                <el-radio v-model="joinForm.cooperationType" label="联合体项目履约生态伙伴" />
                <el-radio v-model="joinForm.cooperationType" label="其他" />
              </div>
            </el-form-item>
            <el-form-item label="公司简介：" prop="content">
              <el-input v-model="joinForm.content" type="textarea" placeholder="请简要描述公司业务和代表项目" style="width: 580px" />
            </el-form-item>
          </el-form>
        </div>
        <div class="submit-btn" @click="submitForm">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { province } from '@/utils/area';
import { saveCooperationForm } from '@/service/api';
export default {
  name: 'JoinUs',
  components: {},
  data() {
    const validateUserName = (rule, value, callback) => {
      if (!this.notEmpty(value)) {
        return callback(new Error('姓名不能为空'));
      } else if (value.length > 30) {
        return callback(new Error('姓名长度不得超过30'));
      } else {
        return callback();
      }
    };
    const validateCompanyName = (rule, value, callback) => {
      if (!this.notEmpty(value)) {
        return callback(new Error('企业全称不能为空'));
      } else if (value.length > 120) {
        return callback(new Error('企业全称不得超过120字'));
      } else {
        callback();
      }
    };
    const validateEmail = (rule, value, callback) => {
      console.log(/^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/.test(value));
      if (!this.notEmpty(value)) {
        return callback(new Error('邮箱不能为空'));
      } else if (value.length > 60) {
        return callback(new Error('邮箱地址不得超过60字'));
      } else if (/^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/.test(value) === false) {
        return callback(new Error('邮箱格式不正确'));
      } else {
        callback();
      }
    };
    // const validateCreditCode = (rule, value, callback) => { // 统一社会信用代码
    //   if (!this.notEmpty(value)) {
    //     return callback(new Error('统一社会信用代码不能为空'));
    //   }
    //   if (/^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(value) === false) {
    //     callback(new Error('统一社会信用代码格式不正确'));
    //   } else {
    //     callback();
    //   }
    // };
    // const validateNickname = (rule, value, callback) => { // 企业负责人
    //   if (!this.notEmpty(value)) {
    //     return callback(new Error('企业负责人姓名不能为空'));
    //   } else if (value.length > 30) {
    //     return callback(new Error('企业负责人姓名不得超过30字'));
    //   } else if (/^[\u4e00-\u9fa5a-zA-z]+$/.test(value) === false) {
    //     return callback(new Error('请使用汉字、大小写英文字母'));
    //   } else {
    //     return callback();
    //   }
    // };
    // const validateLegalPerson = (rule, value, callback) => { // 企业法人
    //   if (!this.notEmpty(value)) {
    //     return callback(new Error('企业法人姓名不能为空'));
    //   } else if (value.length > 30) {
    //     return callback(new Error('企业法人姓名不得超过30字'));
    //   } else if (/^[\u4e00-\u9fa5a-zA-z]+$/.test(value) === false) {
    //     return callback(new Error('请使用汉字、大小写英文字母'));
    //   } else {
    //     return callback();
    //   }
    // };
    // const validateIndustry = (rule, value, callback) => { // 企业所属行业
    //   if (!this.notEmpty(value)) {
    //     return callback(new Error('企业所属行业不能为空'));
    //   } else if (value.length > 64) {
    //     return callback(new Error('企业所属行业不得超过64字'));
    //   } else if (/^[\u4e00-\u9fa5a-zA-z]+$/.test(value) === false) {
    //     callback(new Error('请使用汉字、大小写英文字母'));
    //   } else {
    //     callback();
    //   }
    // };
    const validatePhone = (rule, value, callback) => { // 联系方式
      if (!this.notEmpty(value)) {
        return callback(new Error('联系方式不能为空'));
      }
      if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value) === false) {
        callback(new Error('手机号码格式不正确'));
      } else {
        callback();
      }
    };
    // const validaTecode = (rule, value, callback) => { // 短信验证
    //   if (!this.notEmpty(value)) {
    //     return callback(new Error('短信验证码不能为空'));
    //   }
    //   if (/^[0-9]{4}$/.test(value) === false) {
    //     callback(new Error('短信验证码格式不正确'));
    //   } else {
    //     callback();
    //   }
    // };
    const validateBusinessScope = (rule, value, callback) => { // 经营范围
      if (!this.notEmpty(value)) {
        return callback(new Error('经营区域不能为空'));
      } else if (value.length > 120) {
        return callback(new Error('经营区域不得超过120字'));
      } else {
        callback();
      }
    };
    return {
      joinForm: {
        name: '', // 姓名
        company: '', // 公司
        position: '', // 职位
        content: '', // 公司简介
        email: '', // 邮箱
        phone: '', // 电话
        city: '', // 城市
        operatingArea: '', //	经营地区
        cooperationType: '' // 合作伙伴类型
      },
      joinRules: {
        name: [
          { required: true, validator: validateUserName, trigger: 'blur' }
        ],
        company: [
          { required: true, validator: validateCompanyName, trigger: 'blur' }
        ],
        position: [
          { required: true, message: '请输入您的职位', trigger: 'blur' },
          { min: 0, max: 30, message: '职位长度不得超过30字', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入您的公司简介', trigger: 'blur' },
          { min: 0, max: 120, message: '公司简介长度不得超过12字', trigger: 'blur' }
        ],
        email: [
          { required: true, validator: validateEmail, trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: validatePhone, trigger: 'blur' }
        ],
        city: [
          { required: true, message: '请输入您的所在城市', trigger: 'blur' }
        ],
        operatingArea: [
          { required: true, validator: validateBusinessScope, trigger: 'blur' }
        ],
        cooperationType: [
          { required: true, message: '请输入您的合作伙伴类型', trigger: 'blur' }
        ]
      },
      provincearr: [],
      cityarr: [],
      regionarr: [],
      provinceValue: '',
      cityValue: '',
      RegionValue: '',
      cityData: []
    };
  },
  computed: {},
  watch: {
    cityData: {
      deep: true,
      handler(val) {
        if (val[1]) {
          this.$refs.joinForm.clearValidate('city');
        }
      }
    }
  },
  created() {
    this.provincearr = province;
  },
  mounted() {},
  methods: {
    selectProvimce(id) {
      this.cityarr = [];
      this.regionarr = [];
      this.cityValue = '';
      this.RegionValue = '';
      for (const item of this.provincearr) {
        if (id === item.id) {
          this.$set(this.cityData, 0, item.name);
          this.$refs.joinForm.clearValidate('city');
          this.cityarr = item.children;
        }
      }
    },
    selectcity(id) {
      this.regionarr = [];
      this.RegionValue = '';
      for (const item of this.cityarr) {
        if (id === item.id) {
          this.$set(this.cityData, 1, item.name);
          this.regionarr = item.children;
        }
      }
    },
    selectRegion(id) {
      for (const item of this.regionarr) {
        if (id === item.id) {
          this.$set(this.cityData, 2, item.name);
          this.regionarr = item.children;
        }
      }
    },
    submitForm() {
      const params = this.joinForm;
      if (this.cityData[1]) {
        params.city = this.cityData.join('-');
      }
      this.$refs.joinForm.validate((valid) => {
        if (valid) {
          saveCooperationForm(params).then(() => {
            this.$message.success('提交成功！');
            setTimeout(() => {
              this.$router.push({
                path: '/cooperation'
              });
            }, 500);
          });
        }
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.banner {
  height: 400px;
  .bannerText {
    top: 134px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 40px;
      font-weight: 500;
    }
    .btn {
      width: 251px !important;
      background-color: rgba(255, 255, 255, 0) !important;
      .company {
        color: #2C6EFF;
      }
    }
  }
}
.connect {
  width: 100%;
  height: 1245px;
  background-color: #fafafa;
  .conBox {
    margin: 0 auto;
    width: 1280px;
    height: 100%;
    background-color: #fff;
    .topText {
      height: 190px;
      .title {
        font-size: 34px!important;
      }
    }
    .form-box {
      padding-left: 250px;
      padding-right: 300px;
      .el-form {
        .el-form-item {
          margin-bottom: 34px;
          /deep/.el-form-item__label {
            font-size: 16px;
            color: #252b3a;
            margin-right: 10px;
          }
          .area-box {
            display: flex;
          }
          .radio-box {
            display: flex;
            flex-direction: column;
            .el-radio {
              height: 34px;
              line-height: 34px;
              /deep/.el-radio__input .el-radio__inner {
                width: 18px;
                height: 18px;
              }
              /deep/.el-radio__input.is-checked .el-radio__inner {
                background: rgba(255,255,255,1);
                border-color: rgba(87,93,108,0.80);

                position: relative;
                &::after {
                  width: 9px;
                  height: 9px;
                  background: rgba(87,93,108,0.80);
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%,-50%)scale(1)!important;
                }
              }
              /deep/.el-radio__input+.el-radio__label {
                color: #6a6e77;
                font-size: 15px!important;
              }
              /deep/.el-radio__input.is-checked+.el-radio__label {
                color: #6a6e77;
                font-size: 15px!important;
              }
            }
          }
          /deep/.el-input__inner {
            height: 34px;
          }
          /deep/input::-webkit-input-placeholder { /* WebKit browsers */
            font-size: 15px;
            color: #c5cbd3;
          }

          /deep/input::-moz-placeholder { /* Mozilla Firefox 19+ */
            font-size: 15px;
            color: #c5cbd3;
          }

          /deep/input:-ms-input-placeholder { /* Internet Explorer 10+ */
            font-size: 15px;
            color: #c5cbd3;
          }
        }
      }
    }
    .submit-btn {
      cursor: pointer;
      width: 191px;
      height: 45px;
      background: linear-gradient(0deg,#47bbff 0%, #3898ff 100%);
      border-radius: 3px;
      line-height: 45px;
      text-align: center;
      margin: 110px auto;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
</style>
